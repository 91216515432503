<!-- <psa-orcar-buttom></psa-orcar-buttom> -->
<div class="container_principal_prof">
  <main class="profissional">

    <section class="profissional__header">
      <div class="container">
        <div class="row g-4">
          
          <!-- Coluna para a imagem -->
          <article class="col-lg-6 d-flex align-items-center justify-content-center p-0">
            <div class="profissional__header__hero" *ngIf="loading">
              <div class="profissional__header__hero__img" style="width: 540px; height: 540px;"></div>
            </div>
            <div class="profissional__header__hero" *ngIf="!loading">
              <img class="profissional__header__hero__img" loading="lazy" width="540" height="540"
                [ngSrc]="profissional.photo" [attr.alt]="'Profissional ' + profissional.slug" />
            </div>
          </article>
    
          <!-- Coluna para os textos e botão -->
          <article class="col-lg-6 d-flex flex-column align-items-center justify-content-center p-5 text-start custom-article">
            <div *ngIf="loading" class="profissional__header__info">
              <!-- Placeholder enquanto carrega -->
            </div>
            <div *ngIf="!loading" class="profissional__header__info">
              <div class="profissional__header__info__prefix">PALESTRA DE</div>
              <h1 class="profissional__header__info__title">
                {{ profissional.nome_palestrante }} 
              </h1>
              <p class="profissional__header__info__subtitle">
                {{ profissional.titulo }}
              </p>
              <div class="profissional__header__info__bio" [innerHTML]="profissional.minibio"></div>
              
              <button (click)="goto()" class="profile__button mt-5">SOLICITAR ORÇAMENTO</button>
            </div>
          </article>
    
        </div>
      </div>
    </section>
  
    <section class="profissional__video" *ngIf="videoId">
      <h2 class="profissional__temas__title">
        QUEM É {{ profissional.nome_palestrante }}?
      </h2>
      <div class="video__container">
        <lite-youtube [attr.videoid]="videoId" class="custom-video">
          <a class="lite-youtube-fallback" [href]="linkVideo">Watch on YouTube: "Sample output of devtools-to-video cli tool"</a>
        </lite-youtube>
      </div>
      <button (click)="goto()" class="profile__button mt-5">SOLICITAR ORÇAMENTO</button>
  
    </section>
    
  
    <!-- <psa-form-hire-speaker-top class="mt-5" [speakerName]="profissional.nome_palestrante"></psa-form-hire-speaker-top> -->
  
    <!-- <section class="profissional__temas" *ngIf="assuntos">
      <div class="profissional__temas__wrapper">
        <h2 class="profissional__temas__title">
          Temas Abordados por {{ profissional.nome_palestrante }}
        </h2>
        <div class="profissional__temas__pills">
          <div class="profissional__temas__pills__item" *ngFor="let assunto of assuntos">
            <div>{{ assunto.nome }}</div>
            <div class="profissional__header__info__bio" [innerHTML]="assunto.descricao"></div>  
          </div>
        </div>
      </div>
    </section> -->


    <section class="profissional__temas" *ngIf="assuntos">
      <div class="container">
        <div class="row">
          <header class="col-12 mb-4">
            <h2 class="profissional__temas__title">
              TEMAS DE PALESTRAS DE {{ profissional.nome_palestrante }}
            </h2>
          </header>
          <div class="col-12">
            <div class="row g-4">
              <article class="col-12 col-md-6 mb-4 p-0" *ngFor="let assunto of assuntos">
                <div class="profissional__temas__pills__item p-lg-5 p-md-2 h-100">
                  <h3 class="profissional__assunto__title">{{ assunto.nome }}:</h3>
                  <p class="profissional__assunto__info" [innerHTML]="assunto.descricao"></p>  
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <button (click)="goto()" class="profile__button mt-5">SOLICITAR ORÇAMENTO</button>
    </section>
    
    
    <section class="profissional__palestras" *ngIf="palestrasOferecidas?.length > 0">
      <h2 class="profissional__temas__title">palestras de {{profissional.nome_palestrante}}</h2>
 
      <div class="profissional__palestras__container">
        <div
          *ngFor="let palestra of palestrasOferecidas"
          class="profissional__palestras__items"
          (click)="navigateProposal(palestra)"
        >
          <div class="conteudo_palestra">
            <h2 class="title_palestra">{{ palestra.nome }}</h2>
            <p class="palestra_desc">{{ palestra.descricao }}</p>
          </div>
          <!-- <a style="float: inline-end">>> Saber mais</a> -->
        </div>
      </div>

      <button (click)="goto()" class="profile__button mt-5">SOLICITAR ORÇAMENTO</button>

    </section>
  
    <!-- <section class="profissional__reasons mt-4" *ngIf="profissional.reasons">
      <h2 class="profissional__temas__title mt-3">Por que contratar {{profissional.nome_palestrante}}</h2>
      <div class="profissional__reasons__content mt-5" [innerHTML]="profissional.reasons"></div>
      <div class="profissional__palestras__footer">
        <button (click)="goto()" class="profissional__orcamento__button">
          Solicitar orçamento
        </button>
      </div>
    </section> -->
  
    <!-- <psa-form-hire-speaker [speakerName]="profissional.nome_palestrante"></psa-form-hire-speaker> -->
  
  
  
    <!-- <section class="profissional__depoimentos" *ngIf="depoimentos?.length === 1">
      <div class="container depoimentos__wrapper">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <div *ngIf="depoimentos.length > 0">
              <psa-depoimentos [palestrante]="profissional.nome_palestrante"
                [depoimentos]="depoimentos"></psa-depoimentos>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </section>
  
    <section class="profissional__depoimentos" *ngIf="depoimentos?.length > 1">
      <div class="container depoimentos__wrapper">
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col">
            <div *ngIf="depoimentos.length > 0">
              <psa-depoimentos [palestrante]="profissional.nome_palestrante"
                [depoimentos]="depoimentos"></psa-depoimentos>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </section> -->
  
    <psa-asked-questions *ngIf="profissional.id" [palestrante]="profissional"></psa-asked-questions>
  
    <!-- <psa-form-hire-speaker-bottom [speakerName]="profissional.nome_palestrante"></psa-form-hire-speaker-bottom> -->
  </main>
  
  <psa-relacionados-carousel [relacionados]="profissionaisRelacionados" *ngIf="!loading"></psa-relacionados-carousel>
  

</div>
