<section class="profissional__relacionados text-center" *ngIf="relacionados?.length > 0">
    <h2 class="profissional__temas__title text-center">
      + de 16mil palestrantes cadastrados <br>
      <!-- <p>DOS ESPECIALISTAS DE NICHO AOS MAIS FAMOSOS DO BRASIL</p> -->
    </h2><owl-carousel-o [options]="carouselOptions">
      <ng-container class="w-100" *ngFor="let profissional of relacionados">
        <ng-template carouselSlide>
          <div (click)="navigateToRelacionadoProfile(profissional.slug, profissional.id_tema)">
            <article class="profissional__relacionados__slide d-flex flex-column justify-content-between align-items-center text-center">
              <div class="profissional__relacionados__slide__hero">
                <img
                  class="profissional__relacionado__image"
                  fill
                  ngSrc="{{ profissional.photo }}"
                  alt="{{ 'Palestrante' + profissional.slug }}"
                />
              </div>
              <div class="box-info-prof">
                <h3 class="profissional__relacionados__slide__title">
                  {{ profissional.nome_sobrenome }}
                </h3>
                <p class="profissional__relacionados__slide__text">
                  {{ getMinibio(profissional?.titulo) }}
                </p>
                <button class="custom__button">Perfil Completo</button>
              </div>
            
            </article>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <!-- <a
      (click)="navigateWithUTM()"
      class="profissional__relacionados__link"
    >
      Ver Todos os Especialistas
    </a> -->
</section>
