import { Component, OnInit } from '@angular/core';
import { ProfissionaisService } from 'src/app/services/profissionais.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

export interface IArtigo {
  titulo: string,
  artigo: string,
  resumo: string,
  slug: string,
  image_src: string
  tipo?: string
  fonte?: string
  data?: string
  subtitulo?: string
  id_conteudo_tipo?: number
  categoria?: string
  categoria_slug?: string
}

export interface IFormUser {
  inputs: Array<string>
  requireinputs: Array<string>
  textButton: string

  buttonCenter?: boolean
  showModalSuccess?: boolean
  showError?: boolean
  customRDKey?: string
  customOrigemRD?: 'formulario' | 'whatsapp'
  themeKeyRD?: 'ctr_form' | 'edu_corp_form' | 'espec_form' | 'prof_form'
}

interface IPalestra {
  id: number;
  photo: string;
  nome: string;
  descricao: string;
  foto_tema: string;
}

interface IDepoimento {
  cidade: string;
  data: string;
  descricao: string;
  empresa: string;
  nome: string;
}

interface IProfissionalAssuntos {
  nome: string;
  descricao: string;
}

interface IProfissional {
  photo: string;
  slug: string;
  nome: string;
  sobrenone: string;
  titulo: string;
  minibio: string;
  formatos?: string[];
  palestrante_video: string;
  [key: string]: any;
}

interface IProfissionalRelacionado extends IProfissional {
  nome_sobrenome: string;
}

@Component({
  selector: 'psa-profissionais-perfil',
  templateUrl: './profissionais-perfil.component.html',
  styleUrls: ['./profissionais-perfil.component.css'],
})
export class ProfissionaisPerfilComponent implements OnInit {
  mockReasons = '<p><b>Lorem ipsum dolor sit amet.</b></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus lorem, auctor a nunc vehicula, fermentum molestie turpis. Duis tortor dui, lobortis sit amet iaculis quis, pretium quis nisi. Phasellus ultrices, elit id facilisis iaculis, ex tortor feugiat orci, ac cursus massa erat vitae tellus. Nam in nulla a velit sodales egestas ac non tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut gravida, neque in ultrices hendrerit, nunc lacus tempus diam, eget bibendum nisl nulla ac sem. </p><p>Vivamus sed aliquam mauris, et ultrices lacus. Sed feugiat fermentum sodales. Vestibulum feugiat neque et felis interdum vehicula. Morbi justo eros, tempus non sodales sit amet, maximus et tortor. Phasellus bibendum velit in mattis venenatis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p><p><b>Lorem ipsum dolor sit amet.</b></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus lorem, auctor a nunc vehicula, fermentum molestie turpis. Duis tortor dui, lobortis sit amet iaculis quis, pretium quis nisi. Phasellus ultrices, elit id facilisis iaculis, ex tortor feugiat orci, ac cursus massa erat vitae tellus. Nam in nulla a velit sodales egestas ac non tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut gravida, neque in ultrices hendrerit, nunc lacus tempus diam, eget bibendum nisl nulla ac sem. </p><p>Vivamus sed aliquam mauris, et ultrices lacus. Sed feugiat fermentum sodales. Vestibulum feugiat neque et felis interdum vehicula. Morbi justo eros, tempus non sodales sit amet, maximus et tortor. Phasellus bibendum velit in mattis venenatis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>'
  private _palestras: IPalestra[] = [];
  private _temas_id: string;

  formUserProfile: IFormUser = {
    requireinputs: ['assunto'],
    inputs: ['assunto'],
    textButton: 'RECEBER SOLUÇÕES INCRÍVEIS',
  };

  public isSaNewsLoading: boolean = true;
  public postsBlog: IArtigo[];

  especialistaTemas: Array<string> = [];
  depoimentos: Array<IDepoimento> = [];
  palestrasOferecidas: IPalestra[];
  assuntos: IProfissionalAssuntos[];

  isLoading: boolean = false;
  profissional: IProfissional = {
    photo: '',
    slug: '',
    nome: '',
    sobrenone: '',
    titulo: '',
    minibio: '',
    palestrante_video: '',
  };
  public profissionaisRelacionados: IProfissionalRelacionado[] = [];
  public carouselOptions: OwlOptions = {
    margin: 40,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
  };

  loading: boolean = false;
  linkVideo: any = null;
  videoId: any;
  backgroundUrl: string;

  utmParams: any = {};

  constructor(
    private profissionaisService: ProfissionaisService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public sanitizer: DomSanitizer,
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private title: Title,
  ) {
    // SEO
    this.titleService.setTitle('Palestrante - Profissionais SA');
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = window.location.href;
        this.meta.updateTag({ property: 'og:url', content: currentUrl });
      });

    this.route.queryParamMap.subscribe(
      (result: any) => {
        if(Object.keys(result.params).length === 0) {
          this.utmParams = {
            utm_source: 'organico'
          };
        } else {
          this.utmParams = {};
        }
      }
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe(({ id: slug }) => {
      if (window.history.state.hasOwnProperty('temas_id')) {
        this._temas_id = window.history.state.temas_id;
      }
      if (slug === 'joel-jota' || slug === 'thiago-nigro') {
        window.location.href = 'https://profissionaissa.com';
      }

      if (!slug) {
        window.location.href = 'https://profissionaissa.com';
      }

      this.getPalestrante(slug, this._temas_id);
    });
  }

  public getPalestras(quantity: number = 3): IPalestra[] {
    return this._palestras.slice(0, quantity);
  }

  public async getPalestrante(slug: string, temas_id: string): Promise<any> {
    this.linkVideo = null;
    this.loading = true;
    const res2 = await this.profissionaisService
      .getProfissionalBySlug(slug)
      .catch((err: any) => {
        window.location.href = 'https://profissionaissa.com';
        this.loading = false
      });
      if (!res2.palestrante.id) {
        window.location.href = 'https://profissionaissa.com';
      }
    this.profissionaisRelacionados = res2.relacionados;
    this.depoimentos = res2.depoimentos;
    this.assuntos = res2.assuntos.slice(0,8);

    this.palestrasOferecidas = res2.palestras;
    if (this.palestrasOferecidas.length === 1) {
      this.carouselOptions.responsive = {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 4,
        },
        940: {
          items: 5,
        },
      };
    }

    this.profissional = res2.palestrante;
    this.getUrlVideo();

    this.loading = false;
    this.titleService.setTitle(
      `Contratar ${this.profissional.nome_palestrante}`
    );

    // atualia meta tags
    this.meta.updateTag({ name: 'Title', content: this.profissional.nome_palestrante }, "name=Title");
    this.meta.updateTag({ name: 'Description', content: this.profissional.minibio
   });
  }

  getStyle() {
    const width = window.innerWidth;
    const len = this.palestrasOferecidas.length;
    return { width: len > 2 || width < 800 ? '100%' : len < 2 ? '25%' : '50%' };
  }

  getUrlVideo() {
    if (this.profissional.palestrante_video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/watch?v=${this.profissional.palestrante_video}`
      );
      this.videoId = this.profissional.palestrante_video.split('/').pop().trim();

      return this.linkVideo;
    } else if (this.profissional.palestrante_video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://youtube.com/watch?v=${this.profissional.palestrante_video}`
      );
      this.videoId = this.profissional.palestrante_video.split('/').pop().trim();
    } else {
      this.linkVideo = null;
      return null;
    }
  }

  navigateProposal(proposal: any) {
    this.router.navigate(['palestra/', (proposal.slug)], { queryParamsHandling: 'merge', queryParams: this.utmParams});
  }

  goto() {
    // this.viewportScroller.scrollToAnchor('https://profissionaissa.com/contato/?utm_source=organico');
    window.location.href = 'https://profissionaissa.com/contato/?utm_source=organico';
  }
}
